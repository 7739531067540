import React from "react";
import { addDevice } from "../../Services/devices";

// Dependencies
import "@ingka/forms/style.scss";
import "@ingka/button/style.scss";
import "react-drop-zone/dist/styles.css";
import "@ingka/toast/style.scss";
import "@ingka/loading/style.scss";

// Components
import Text from "@ingka/text";
import InputField from "@ingka/input-field";
import Button from "@ingka/button";
import SideBar from "./SideBar";
import FormField from "@ingka/form-field";
import TextArea from "@ingka/text-area";
import Dropzone from "react-dropzone";
import Toast from "@ingka/toast";
import InputError from "./DashboardCompoenents/InputError";
import Loading, { LoadingBall } from "@ingka/loading";
import { Formik, Form } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageTitle from "../PageTitle";

export default function DeviceAdd(props) {
  const [toastVisible, setToastVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [imageInput, setImageInput] = React.useState(null);

  const formData = new FormData();

  const initialValues = {
    device_name: "",
    device_desc: "",
    img: null,
  };

  const validationSchema = Yup.object({
    device_name: Yup.string().required("Required"),
    device_desc: Yup.string().required("Required"),
    img: Yup.mixed().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    formData.append("device_name", values.device_name);
    formData.append("device_desc", values.device_desc);
    formData.append("file", values.img);
    setLoader(true);
    addDevice(formData)
      .then((res) => {
        if (res.data.success === 1) {
          setLoader(false);
          setMessage("Device Added Successfully!");
          setToastVisible(true);
          resetForm({ values: null });
        } else {
          setMessage(res.data.message);
          setLoader(false);
          setToastVisible(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setLoader(false);
        setToastVisible(true);
      });
  };

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <>
      <PageTitle title={props.title} />
      <Toast
        text={
          <>
            <strong>{message}</strong>
          </>
        }
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <SideBar />
      <div className="dashboard">
        {loader && (
          <Loading className="loader" text="Uploading Data..." labelTransitions>
            <LoadingBall />
          </Loading>
        )}

        <div className="mt-50 contact-form">
          <div className="dashboard-container">
            <Text tagName="h1" headingSize="m">
              Add New Device
            </Text>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="input">
                      <FormField
                        valid={touched.device_name && !errors.device_name}
                        shouldValidate={touched.device_name}
                        validation={{
                          msg: errors.device_name,
                          id: "Error-id:",
                        }}
                      >
                        <InputField
                          value={values.device_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="device_name"
                          name="device_name"
                          label="Device Name"
                        />
                      </FormField>
                    </div>
                    <div className="input">
                      <FormField
                        valid={touched.device_desc && !errors.device_desc}
                        shouldValidate={touched.device_desc}
                        validation={{
                          msg: errors.device_desc,
                          id: "Error-id:",
                        }}
                      >
                        <TextArea
                          value={values.device_desc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="device_desc"
                          name="device_desc"
                          label="Device Description"
                          style={{ height: "100px" }}
                        />
                      </FormField>
                    </div>

                    <div className="mt-50">
                      <label>
                        Drop Image ( Accepted Files: jpg, jpeg, png, webp )
                      </label>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          values.img = acceptedFiles[0];
                          setImageInput(acceptedFiles[0]);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p className="dropzone">Drop Image Here....</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <InputError touched={touched.img} errors={errors.img} />
                      <div>Selected File: {imageInput && imageInput.name}</div>
                    </div>

                    <Button
                      className="mt-50"
                      type="primary"
                      htmlType="submit"
                      text="Add Device"
                      small
                      disabled={!formik.isValid && formik.isSubmitting}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
