import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { addFaq, getFaqs, deleteFaq as del } from "../../Services/faq";
import Modal from "react-modal/lib/components/Modal";

// Dependencies
import "@ingka/forms/style.scss";
import "@ingka/button/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/toast/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/loading/style.scss";
import "@ingka/table/style.scss";

// Components
import Text from "@ingka/text";
import InputField from "@ingka/input-field";
import FormField from "@ingka/form-field";
import Button from "@ingka/button";
import SideBar from "./SideBar";
import Toast from "@ingka/toast";
import Loading, { LoadingBall } from "@ingka/loading";
import Table from "@ingka/table";
import { Formik, Form } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageTitle from "../PageTitle";

export default function Faq(props) {
  Modal.setAppElement("#root");
  const [toastVisible, setToastVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [faqs, setFaqs] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [removeFaq, setRemoveFaq] = React.useState();
  const { id } = useParams();

  useEffect(() => {
    getFaqs(id).then((res) => {
      setFaqs(res.data);
    });
  }, [id]);

  function deleteFaq() {
    del(removeFaq)
      .then((res) => {
        setModal(false);
        if (res.data.success === 1) {
          window.location.reload();
        } else {
          setModal(false);
          setMessage(res.data.message);
          setToastVisible(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setLoader(false);
        setToastVisible(true);
      });
  }

  const initialValues = {
    question: "",
    answer: "",
    device_id: id,
  };

  const validationSchema = Yup.object({
    question: Yup.string().required("Required"),
    answer: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    setLoader(true);
    addFaq(values)
      .then((res) => {
        if (res.data.success === 1) {
          setMessage("FAQ Added Successfully!");
          setLoader(false);
          setToastVisible(true);
          resetForm({ values: "" });
          window.location.reload();
        } else {
          setMessage(res.data.message);
          setLoader(false);
          setToastVisible(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setLoader(false);
        setToastVisible(true);
      });
  };

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <>
      <PageTitle title={props.title} />
      <Modal
        className="Modal"
        isOpen={modal}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="modal-body">
          <div>
            <Text tagName="h1" headingSize="m" align="center">
              All information related to this FAQ will be deleted.
              <br />
              <br />
              Confirm delete FAQ?
            </Text>
            <div>
              <Button
                type="secondary"
                onClick={() => {
                  setModal(false);
                }}
                text="Cancel"
              />
              <Button
                type="primary"
                onClick={() => {
                  deleteFaq();
                }}
                text="Delete"
              />
            </div>
          </div>
        </div>
      </Modal>
      <Toast
        text={
          <>
            <strong>{message}</strong>
          </>
        }
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <SideBar />
      <div className="dashboard">
        {loader && (
          <Loading className="loader" text="Uploading Data..." labelTransitions>
            <LoadingBall />
          </Loading>
        )}
        <div className="mt-50 contact-form">
          <div className="dashboard-container">
            <Text tagName="h1" headingSize="m">
              Add FAQ
            </Text>
            <div className="faq_container">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="col-2-layout">
                        <div className="input">
                          <FormField
                            valid={touched.question && !errors.question}
                            shouldValidate={touched.question}
                            validation={{
                              msg: errors.question,
                              id: "Error-id:",
                            }}
                          >
                            <InputField
                              value={values.question}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="question"
                              name="question"
                              label="Question"
                            />
                          </FormField>
                        </div>
                        <div className="input">
                          <FormField
                            valid={touched.answer && !errors.answer}
                            shouldValidate={touched.answer}
                            validation={{
                              msg: errors.answer,
                              id: "Error-id:",
                            }}
                          >
                            <InputField
                              value={values.answer}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="answer"
                              name="answer"
                              label="Answer"
                            />
                          </FormField>
                        </div>
                      </div>
                      <Button
                        type="primary"
                        htmlType="submit"
                        text="Add"
                        small
                        disabled={!formik.isValid && formik.isSubmitting}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <div className="faq_table mt-50">
              <Text tagName="h2" headingSize="m">
                Frequestly Asked Questions
              </Text>
              <Table inset striped={1}>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {faqs.map((item, index) => (
                    <tr key={index}>
                      <td>{item.question}</td>
                      <td>{item.answer}</td>
                      <td>
                        <Button
                          id={item.faq_id}
                          type="primary"
                          htmlType="submit"
                          text="Delete"
                          small
                          onClick={() => {
                            setModal(true);
                            setRemoveFaq(item.faq_id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
