import http from "./http";
import { apiURL } from "./apiendpoint";

export function getFaqs(id) {
  return http.get(apiURL + "faq/" + id);
}

export function deleteFaq(id) {
  return http.delete(apiURL + "faq/" + id);
}

export function addFaq(data) {
  return http.post(apiURL + "faq", data);
}
