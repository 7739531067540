import React from "react";
import { sendMail } from "../Services/mail";
import ReCAPTCHA from "react-google-recaptcha";
import email from "../Assets/Images/email.png";

// Dependencies
import "@ingka/page-container/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/search/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/button/style.scss";
import "@ingka/grid/style.scss";
import "@ingka/forms/style.scss";
import "@ingka/toast/style.scss";

// Components
import Text from "@ingka/text";
import TextArea from "@ingka/text-area";
import Button from "@ingka/button";
import Navbar from "./Common/Navbar";
import Footer from "./Common/Footer";
import InputField from "@ingka/input-field";
import Toast from "@ingka/toast";
import { Formik, Form } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputError from "./Dashboard/DashboardCompoenents/InputError";
import PageTitle from "./PageTitle";

export default function Contact(props) {
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const [toastVisible, setToastVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const reRef = React.createRef();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    token: "",
    sitekey: siteKey,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format.").required("Required"),
    message: Yup.string().required("Required"),
    token: Yup.string(),
  });

  const onSubmit = async (values, { resetForm }) => {
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    values.token = token;
    console.log(values);
    sendMail(values)
      .then((res) => {
        if (res.data.success === 1) {
          setMessage(
            "Mail Send to Ikea Department, they will contact you soon!"
          );
          setToastVisible(true);
          resetForm({ values: "" });
        } else {
          setMessage(res.data.message);
          setToastVisible(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setToastVisible(true);
      });
    resetForm({ values: "" });
  };

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  return (
    <>
      <PageTitle title={props.title} />
      <Toast
        text={
          <>
            <strong>{message}</strong>
          </>
        }
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <Navbar />
      <header className="header-contact">
        <div>
          <Text tagName="h1" bodySize="l">
            Contact Us
          </Text>
        </div>
      </header>
      <div className="container">
        <Text className="mt-100" tagName="h5" bodySize="s" align="center">
          If you have any questions or need help regarding third party code in
          IKEA products, please contact us by filling out the form or by writing
          to us at the address given below.
        </Text>
      </div>

      <div className="mt-100 contact-form">
        <div className="container-l">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="col-2-layout">
                    <div className="input">
                      <InputField
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="firstName"
                        name="firstName"
                        label="First Name"
                      />
                      <InputError
                        touched={touched.firstName}
                        errors={errors.firstName}
                      />
                    </div>
                    <div className="input">
                      <InputField
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                      />
                      <InputError
                        touched={touched.lastName}
                        errors={errors.lastName}
                      />
                    </div>
                  </div>

                  <div className="input">
                    <InputField
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="email"
                      name="email"
                      label="Email"
                    />
                    <InputError touched={touched.email} errors={errors.email} />
                  </div>
                  <div className="input">
                    <TextArea
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="message"
                      name="message"
                      label="Message"
                      style={{ height: "200px" }}
                    />
                    <InputError
                      touched={touched.message}
                      errors={errors.message}
                    />
                  </div>
                  <div className="mt-50">
                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={onChange}
                      ref={reRef}
                      size="invisible"
                    />
                  </div>

                  <div className="mt-50 textCenter">
                    <Button
                      type="primary"
                      htmlType="submit"
                      text="Send"
                      disabled={!formik.isValid || formik.isSubmitting}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div className="mt-100 mail-address">
            <div className="col-2-layout">
              <div>
                <img src={email} className="mail-icon" />
              </div>
              <div>
                <Text tagName="h6" bodySize="m">
                  IKEA IT AB c/o PostNord
                </Text>
                <Text tagName="p" bodySize="s">
                  Open Source Program Office
                  <br />
                  Axel Danielssons väg 259
                  <br />
                  215 82 Malmö
                  <br />
                  Sweden
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
