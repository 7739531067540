import axios from "axios";
import Cookies from "universal-cookie";
import { sec } from "../Auth/security";
import { accessTokenSign } from "../Auth/Auth";
const cookies = new Cookies();

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

/**This axios interceptor adds the accessToken with requests
 * @function Axios-interceptor-request
 *
 */
axios.interceptors.request.use(
  async (config) => {
    let accessToken = cookies.get("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      if (sec.isAuthenticated()) {
        accessToken = await sec.getAccessTokenSilently()();
        if (accessToken) {
        }
        accessTokenSign(accessToken);
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * This axios response check the status, if the status is unauthorized then is will try one
 * moretime after requesting new access token if the response if same again it will forward the response.
 *
 * @function Axios-interceptor-response
 *
 * @param {response}
 */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
