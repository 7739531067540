import { render } from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function PageTitle(props) {
  return (
    <>
      <HelmetProvider>
        <div>
          <Helmet>
            <title>{props.title}</title>
          </Helmet>
        </div>
      </HelmetProvider>
    </>
  );
}
