import React from "react";

export default function InputError(props) {
  return (
    <>
      {props.touched && props.errors ? (
        <p className="error">{props.errors}</p>
      ) : null}
    </>
  );
}
