import React, { useEffect } from "react";
import { getDevices, searchDevice } from "../Services/devices";
import PageTitle from "./PageTitle";

// Dependencies
import "@ingka/page-container/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/search/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/button/style.scss";
import "@ingka/grid/style.scss";

// Components
import Search from "@ingka/search";
import Text from "@ingka/text";
import Button from "@ingka/button";
import Navbar from "./Common/Navbar";
import Footer from "./Common/Footer";
import DeviceCard from "./DeviceCard";
import Hyperlink from "@ingka/hyperlink";

export default function Home(props) {
  const [devices, setdevices] = React.useState([]);
  const [searchButton, setSearchButton] = React.useState(false);
  const [visibleCount, setVisibleCount] = React.useState(30);
  const [visible, setVisible] = React.useState(visibleCount);

  const loadMore = () => {
    setVisible(visible + visibleCount);
  };

  const resetResult = () => {
    getDevices().then((res) => {
      setdevices(res.data);
      setSearchButton(false);
    });
  };

  const search = (e, data) => {
    searchDevice(data.value).then((res) => {
      setdevices(res.data);
      setSearchButton(true);
    });
  };

  useEffect(() => {
    getDevices().then((res) => {
      setdevices(res.data);
    });
  }, []);

  return (
    <>
      <PageTitle title={props.title} />
      <Navbar />
      <header className="header-main">
        <div>
          <Text tagName="h1" bodySize="l">
            IKEA GPL CODE CENTER
          </Text>

          <Search
            className="mt-50"
            onSearch={(e, data) => {
              search(e, data);
            }}
            placeholder="Search for product"
          />
        </div>
      </header>
      <div className="devices-section container">
        <Text className="mt-100" tagName="p" bodySize="m" align="">
          Some IKEA products may contain software code developed by third
          parties, including software code subject to GNU Public License
          (“GPL”), Version 1, 2 or 3 or to the GNU Lesser General Public License
          ("LGPL").
          <br />
          <br />
          In order to comply with the terms of the GPL the respective IKEA
          company which offers respective software for download or is
          responsible for the distribution of products that contain respective
          code, offers, where applicable, by itself or with the support of third
          parties, mail service in order to obtain a machine-readable source
          code of the software subject to the GPL, on a CD upon request by a
          letter to our company address, which can be found in our contacts at
          this web site. Further information is provided with the relevant
          products or the software.
          <br />
          <br />
          With this GPL Code Center, IKEA provides free download of copies of
          the respective machine readable source code of the software, which is
          subject to the GPL and contained in IKEA products. The respective
          programs are distributed WITHOUT ANY WARRANTY; without even the
          implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR
          PURPOSE. See the respective GNU General Public License for more
          details.
          <br />
          <br />
          If you are of the opinion that IKEA should offer further source code
          subject to the GPL, please contact us using the form given on
          <Hyperlink
            color="black"
            button
            href="/contact"
            text=" Contact Us Page."
          />
        </Text>
        <Text className="mt-100" tagName="h1" headingSize="b" align="center">
          All Devices
        </Text>
        <div className=" textCenter">
          {searchButton && (
            <Button
              type="primary"
              text="Clear |  X"
              small
              onClick={resetResult}
            />
          )}
          {searchButton && devices.length == 0 && (
            <Text className="mt-50" tagName="h6" bodySize="l">
              No Results found!
            </Text>
          )}
        </div>

        <div>
          <div id="DevicesSection" className=" mt-100 col-3-layout">
            {devices.slice(0, visible).map((item, index) => (
              <DeviceCard
                key={index}
                device_name={item.device_name}
                device_desc={item.device_desc.substr(0, 150) + "..."}
                versions={item.version}
                imgURL={item.img}
                href={"/product/" + item.device_id}
                id={item.device_id}
              />
            ))}
          </div>
          {devices.length >= visible && (
            <div className="mt-100 textCenter">
              <Button type="secondary" text="View More" onClick={loadMore} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
