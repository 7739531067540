import React from "react";
import logo from "../../Assets/Images/logo.png";

import "@ingka/focus/style.scss";
import "@ingka/hyperlink/style.scss";

import Hyperlink from "@ingka/hyperlink";

export default function Navbar() {
  const menuitems = [
    { title: "Devices", url: "/" },
    // { title: "Customer Support", url: "#" },
    { title: "Contact Us", url: "/contact" },
  ];

  const [clicked, setClicked] = React.useState(false);
  const setClickState = () => {
    setClicked(!clicked);
  };

  return (
    <>
      <div className="container">
        <nav>
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="nav-toggle" onClick={setClickState}>
            <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <ul className={clicked ? "nav-menu active" : "nav-menu"}>
            {menuitems.map((item, index) => {
              return (
                <li key={index}>
                  <Hyperlink
                    className="link--subtle .link--grey"
                    url={item.url}
                    text={item.title}
                  />
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
}
