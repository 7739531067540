import React, { useEffect } from "react";
import Modal from "react-modal/lib/components/Modal";
import { getDevices, deleteDevice as del } from "../../Services/devices";

// Dependencies
import "@ingka/page-container/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/search/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/button/style.scss";
import "@ingka/grid/style.scss";
import "@ingka/toast/style.scss";

// Components
import Text from "@ingka/text";
import Button from "@ingka/button";
import SideBar from "./SideBar";
import DashboardDeviceCard from "./DashboardCompoenents/DashboardDeviceCard";
import Toast from "@ingka/toast";
import PageTitle from "../PageTitle";

export default function DevicePanel(props) {
  Modal.setAppElement("#root");
  const [toastVisible, setToastVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [devices, setdevices] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [removeDevice, setRemoveDevice] = React.useState();

  function handleRemove(id) {
    setRemoveDevice(id);
  }

  function deleteDevice() {
    del(removeDevice)
      .then((res) => {
        setModal(false);
        if (res.data.success === 1) {
          window.location.reload();
        } else {
          setModal(false);
          setMessage(res.data.message);
          setToastVisible(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setToastVisible(true);
      });
  }

  useEffect(() => {
    getDevices().then((res) => {
      setdevices(res.data);
    });
  }, []);

  return (
    <>
      <PageTitle title={props.title} />
      <Toast
        text={
          <>
            <strong>{message}</strong>
          </>
        }
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <Modal
        className="Modal"
        isOpen={modal}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="modal-body">
          <div>
            <Text tagName="h2" headingSize="m" align="center">
              All information related to this Device will be deleted.
            </Text>
            <Text tagName="h6" headingSize="m" align="center">
              Confirm delete Device?
            </Text>
            <div>
              <Button
                type="secondary"
                onClick={() => {
                  setModal(false);
                }}
                text="Cancel"
              />
              <Button
                type="primary"
                onClick={() => {
                  deleteDevice();
                }}
                text="Delete"
              />
            </div>
          </div>
        </div>
      </Modal>
      <SideBar />
      <div className="dashboard">
        <div className="">
          <div className="dashboard-container">
            <Text tagName="h1" headingSize="m">
              All Devices
            </Text>
            <div id="DevicesSection" className=" mt-50 col-3-layout">
              {devices.map((item, index) => (
                <DashboardDeviceCard
                  key={index}
                  device_id={item.device_id}
                  device_name={item.device_name}
                  device_desc={item.device_desc.substr(0, 150) + "..."}
                  versions={item.version}
                  modal={setModal}
                  handleRemove={handleRemove}
                  imgURL={item.img}
                  href={"/product/" + item.device_id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
