import React, { useEffect } from "react";

// Dependencies
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/aspect-ratio-image/style.scss";
import "@ingka/image-info/style.scss";
import "@ingka/card/style.scss";
import "@ingka/focus/style.scss";

// Components
import Card from "@ingka/card";
import react from "react";

export default function DeviceCard(props) {
  const [imgURL, setimgURL] = react.useState("");

  useEffect(() => {
    setimgURL(props.imgURL);
  });

  return (
    <>
      <Card
        aspectRatioImageProps={{
          alt: "Img alt text",
          sizes: "square",
          src: imgURL,
        }}
        backgroundColor="#F5F5F5"
        body={props.device_desc}
        buttonVariant="primary"
        compact
        ctaLabel="View Versions"
        emphasised
        href={props.href}
        imagePosition="trailing"
        label={props.versions + " versions"}
        textColor={null}
        title={props.device_name}
      />
    </>
  );
}
