import React from "react";

// Dependencies
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/aspect-ratio-image/style.scss";
import "@ingka/image-info/style.scss";
import "@ingka/card/style.scss";
import "@ingka/focus/style.scss";

// Components
import Button from "@ingka/button";
import Text from "@ingka/text";

export default function DashboardDeviceCard(props) {
  return (
    <>
      <div className="version_card">
        <div className="between">
          <Text tagName="h5" bodySize="m" className="m-0">
            Version:
          </Text>
          <Text tagName="p" bodySize="s" className="m-0">
            {props.version}
          </Text>
        </div>
        <div className="between">
          <Text tagName="h5" bodySize="m" className="m-0">
            Release Date:
          </Text>
          <Text tagName="p" bodySize="s" className="m-0">
            {props.release_date}
          </Text>
        </div>
        <div className="between">
          <Text tagName="h5" bodySize="m" className="m-0">
            GitHub:
          </Text>
          <Text tagName="p" bodySize="s" className="m-0">
            <a target={"blank"} href={props.gpl_directory}>
              Open Github
            </a>
          </Text>
        </div>
        <div className="between">
          <Text tagName="h5" headingSize="s" className="m-0">
            Description
          </Text>
        </div>
        <div className="between">
          <Text tagName="h5" bodySize="s" className="m-0">
            {props.version_desc}
          </Text>
        </div>

        {/* <Link to="/admin/version-edit" state={props.v}> */}
        <Button
          className="mr-10"
          href={"/admin/version-edit/" + props.version_id}
          type="primary"
          text="Edit"
          small
        />
        {/* </Link> */}

        <Button
          id={props.version_id}
          className="mr-10"
          type="secondary"
          onClick={() => {
            props.modal(true);
            props.handleRemove(props.version_id);
          }}
          text="Delete"
          small
        />
      </div>
    </>
  );
}
