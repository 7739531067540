import http from "./http";
import { apiURL } from "./apiendpoint";

export function addVersion(data) {
  return http.post(apiURL + "versions", data);
}

export function getVersions(id) {
  return http.get(apiURL + "versions/" + id);
}

export function getVersionDetails(id) {
  return http.get(apiURL + "versions/vid/" + id);
}

export function updateVersion(id, data) {
  return http.put(apiURL + "versions/" + id, data);
}

export function deleteVersion(id) {
  return http.delete(apiURL + "versions/" + id);
}
