import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { sec } from "./Auth/security";

import "./App.scss";
import Home from "./Components/Home";
import Product from "./Components/Product";
import Contact from "./Components/Contact";
import Login from "./Components/Dashboard/login";
import DevicePanel from "./Components/Dashboard/DevicePanel";
import VersionAdd from "./Components/Dashboard/VersionAdd";
import VersionView from "./Components/Dashboard/VersionView";
import DeviceAdd from "./Components/Dashboard/DeviceAdd";
import VersionEdit from "./Components/Dashboard/VersionEdit";
import DeviceEdit from "./Components/Dashboard/DeviceEdit";
import Faq from "./Components/Dashboard/Faq";
import Page404 from "./Components/404";
import CallBack from "./Components/CallBack";
import ProtectedRoute from "./Auth/ProtectedRoute";

export default function App() {
  const title = {
    home: "Home | IKEA Home",
    product: "IKEA Devices | GPL Archive",
    contact: "Contact Us",
    devicePanel: "Devices Dashboard",
    versionAdd: "Devices Dashboard",
    viewVersion: "View Versions",
    addDevice: "Add Device",
    editVersion: "Edit Version",
    editDevice: "Edit Device",
    manageFAQ: "Manage FAQ",
    page404: "404",
    callBack: "Authorization Callback",
  };

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);
  sec.setIsAuthenticated(isAuthenticated);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home title={title.home} />} />
          <Route
            path="/product/:id"
            element={<Product title={title.product} />}
          />
          <Route path="/contact" element={<Contact title={title.contact} />} />
          <Route path="/login" element={<Login title={title.login} />} />
          <Route
            path="/admin/devices"
            element={
              <ProtectedRoute
                component={DevicePanel}
                title={title.devicePanel}
              />
            }
          />
          <Route
            path="/admin/version-add/:id"
            element={
              <ProtectedRoute component={VersionAdd} title={title.versionAdd} />
            }
          />
          <Route
            path="/admin/version-view/:id"
            element={
              <ProtectedRoute
                component={VersionView}
                title={title.viewVersion}
              />
            }
          />
          <Route
            path="/admin/device-add"
            element={
              <ProtectedRoute component={DeviceAdd} title={title.addDevice} />
            }
          />
          <Route
            path="/admin/version-edit/:id"
            element={
              <ProtectedRoute
                component={VersionEdit}
                title={title.VersionEdit}
              />
            }
          />
          <Route
            path="/admin/device-edit/:id"
            element={
              <ProtectedRoute component={DeviceEdit} title={title.editDevice} />
            }
          />
          <Route
            path="/admin/faq/:id"
            element={<ProtectedRoute component={Faq} title={title.manageFAQ} />}
          />
          <Route path="*" element={<Page404 />} title={title.page404} />
          <Route
            path="/auth/callback"
            element={<CallBack />}
            title={title.callBack}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
