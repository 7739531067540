import React from "react";
import logo from "../../Assets/Images/logo.png";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// Dependencies
import "@ingka/focus/style.scss";
import "@ingka/hyperlink/style.scss";

// Components
import Text from "@ingka/text";

export default function SideBar() {
  const { logout } = useAuth0();

  const menuitems = [
    { title: "Devices", url: "/admin/devices" },
    { title: "Add Device", url: "/admin/device-add" },
  ];

  return (
    <>
      <div className="side-bar">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="side-links">
          {menuitems.map((item, index) => {
            return (
              <div key={index} className="">
                <NavLink
                  className="side-bar-link item"
                  style={({ isActive }) => {
                    return {
                      display: "block",
                      backgroundColor: isActive ? "#c9c9c9" : "",
                    };
                  }}
                  to={item.url}
                  key={index}
                >
                  {item.title}
                </NavLink>
              </div>
            );
          })}
        </div>
        <Text
          className="item logout"
          tagName="h5"
          headingSize="xs"
          onClick={logout}
        >
          Logout
        </Text>
      </div>
    </>
  );
}
