import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { addVersion } from "../../Services/versions";

// Dependencies
import "@ingka/forms/style.scss";
import "@ingka/button/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/toast/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/loading/style.scss";

// Components
import Text from "@ingka/text";
import InputField from "@ingka/input-field";
import TextArea from "@ingka/text-area";
import FormField from "@ingka/form-field";
import Button from "@ingka/button";
import SideBar from "./SideBar";
import InputError from "./DashboardCompoenents/InputError";
import Toast from "@ingka/toast";
import Loading, { LoadingBall } from "@ingka/loading";
import { Formik, Form } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageTitle from "../PageTitle";

export default function VersionAdd(props) {
  const navigate = useNavigate();
  const [toastVisible, setToastVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const dev_id = useParams().id;

  function cancel() {
    navigate(`/admin/version-view/${dev_id}`);
  }

  const initialValues = {
    version: "",
    version_desc: "",
    release_date: "",
    gpl_directory: "",
    gpl_lisence: "",
    device_id: dev_id,
  };

  const validationSchema = Yup.object({
    version: Yup.string().required("Required"),
    version_desc: Yup.string().required("Required"),
    release_date: Yup.date().required("Required"),
    gpl_directory: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z])(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
        "Enter Valid url example: 'https://github.com/git_repoistory'"
      )
      .required("Required"),
    gpl_lisence: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z])(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
        "Enter Valid file url example: 'https://github.com/git_repoistory/file'"
      )
      .required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    setLoader(true);

    addVersion(values)
      .then((res) => {
        if (res.data.success === 1) {
          setMessage("Version Added Successfully!");
          setLoader(false);
          setToastVisible(true);
          resetForm({ values: "" });
        } else {
          setMessage(res.data.message);
          setLoader(false);
          setToastVisible(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setLoader(false);
        setToastVisible(true);
      });
  };

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <>
      <PageTitle title={props.title} />
      <Toast
        text={
          <>
            <strong>{message}</strong>
          </>
        }
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <SideBar />
      <div className="dashboard">
        {loader && (
          <Loading className="loader" text="Uploading Data..." labelTransitions>
            <LoadingBall />
          </Loading>
        )}
        <div className="mt-50 contact-form">
          <div className="dashboard-container">
            <Text tagName="h1" headingSize="m">
              Add Version
            </Text>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="col-2-layout">
                      <div className="input">
                        <FormField
                          valid={touched.version && !errors.version}
                          shouldValidate={touched.version}
                          validation={{
                            msg: errors.version,
                            id: "Error-id:",
                          }}
                        >
                          <InputField
                            value={values.version}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="version"
                            name="version"
                            label="Version"
                          />
                        </FormField>
                      </div>

                      <div className="input">
                        <label className="label">Release Date</label>
                        <input
                          type="date"
                          className="date-picker"
                          value={values.release_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          min="2000-01-01"
                          max="2100-01-01"
                          id="release_date"
                          name="release_date"
                        />
                        <InputError
                          touched={touched.release_date}
                          errors={errors.release_date}
                        />
                      </div>
                    </div>
                    <div className="col-2-layout">
                      <div className="input">
                        <FormField
                          valid={touched.gpl_directory && !errors.gpl_directory}
                          shouldValidate={touched.gpl_directory}
                          validation={{
                            msg: errors.gpl_directory,
                            id: "Error-id:",
                          }}
                        >
                          <InputField
                            value={values.gpl_directory}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="gpl_directory"
                            name="gpl_directory"
                            label="Location / URL of GPL code"
                          />
                        </FormField>
                      </div>
                      <div className="input">
                        <FormField
                          valid={touched.gpl_lisence && !errors.gpl_lisence}
                          shouldValidate={touched.gpl_lisence}
                          validation={{
                            msg: errors.gpl_lisence,
                            id: "Error-id:",
                          }}
                        >
                          <InputField
                            value={values.gpl_lisence}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="gpl_lisence"
                            name="gpl_lisence"
                            label="Location / URL of License Information"
                          />
                        </FormField>
                      </div>
                    </div>
                    <div className="input">
                      <FormField
                        valid={touched.version_desc && !errors.version_desc}
                        shouldValidate={touched.version_desc}
                        validation={{
                          msg: errors.version_desc,
                          id: "Error-id:",
                        }}
                      >
                        <TextArea
                          value={values.version_desc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="version_desc"
                          name="version_desc"
                          label="Version Description"
                          style={{ height: "200px" }}
                        />
                      </FormField>
                    </div>
                    <Button
                      type="secondary"
                      text="Go Back"
                      onClick={cancel}
                      small
                    />
                    <Button
                      type="primary"
                      htmlType="submit"
                      text="Add Version"
                      small
                      disabled={!formik.isValid && formik.isSubmitting}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
