import React from "react";

// Dependencies
import "@ingka/page-container/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/grid/style.scss";

// Components
import Text from "@ingka/text";
import Navbar from "./Common/Navbar";
import Footer from "./Common/Footer";
import PageTitle from "./PageTitle";

export default function Page404(props) {
  return (
    <>
      <PageTitle title={props.title} />
      <Navbar />
      <div className="page-404">
        <div className="card">
          <Text tagName="h1" bodySize="b" align="center">
            404
          </Text>
          <Text tagName="p" bodySize="m" align="center">
            Page not found!
          </Text>
        </div>
      </div>
      <Footer />
    </>
  );
}
