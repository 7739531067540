import React from "react";
import logo from "../../Assets/Images/logo.png";

import "@ingka/focus/style.scss";
import "@ingka/hyperlink/style.scss";

import Text from "@ingka/text";
import Hyperlink from "@ingka/hyperlink";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container col-2-layout">
          <div>
            <img src={logo} alt="" />
            <Text tagName="p" headingSize="xs">
              © Inter IKEA Systems B.V. 1999 - {new Date().getFullYear()}
            </Text>
          </div>
          <div>
            <Hyperlink
              className="link--subtle .link--grey"
              url="/"
              text="Devices"
            />
            {/* <Hyperlink
              className="link--subtle .link--grey"
              url="http://www.ikea.com"
              text="Customer Suport"
            /> */}
            <Hyperlink
              className="link--subtle .link--grey"
              url="/contact"
              text="Contact Us"
            />
          </div>
        </div>
      </footer>
    </>
  );
}
