import React from "react";
import Loading, { LoadingBall } from "@ingka/loading";

// Dependencies
import "@ingka/loading/style.scss";

export default function LoadingScreen(props) {
  return (
    <>
      <div>
        <Loading className="loader" text="Loading..." labelTransitions>
          <LoadingBall />
        </Loading>
      </div>
    </>
  );
}
