import http from "./http";
import { apiURL } from "./apiendpoint";

export function getDevices() {
  return http.get(apiURL + "devices");
}

export function getDeviceDetails(id) {
  return http.get(apiURL + "devices/details/" + id);
}

export function addDevice(data) {
  return http.post(apiURL + "devices", data);
}

export function updateDevice(id, data) {
  return http.put(apiURL + "devices/" + id, data);
}

export function deleteDevice(id) {
  return http.delete(apiURL + "devices/" + id);
}

export function searchDevice(data) {
  return http.get(apiURL + "devices/search/" + data);
}
