import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getVersionDetails, updateVersion } from "../../Services/versions";

// Dependencies
import "@ingka/forms/style.scss";
import "@ingka/button/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/toast/style.scss";
import "@ingka/focus/style.scss";

// Components
import Text from "@ingka/text";
import InputField from "@ingka/input-field";
import Button from "@ingka/button";
import SideBar from "./SideBar";
import InputError from "./DashboardCompoenents/InputError";
import TextArea from "@ingka/text-area";
import Toast from "@ingka/toast";
import FormField from "@ingka/form-field";
import { Formik, Form } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageTitle from "../PageTitle";

export default function VersionEdit(props) {
  const navigate = useNavigate();
  const [toastVisible, setToastVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [version, setVersion] = React.useState(null);
  const [dev_id, setDev_id] = React.useState(null);
  const ver_id = useParams().id;

  function cancel() {
    navigate(`/admin/version-view/${dev_id}`);
  }

  useEffect(() => {
    getVersionDetails(ver_id).then((res) => {
      setVersion(res.data);
      setFieldValue("version", res.data.version);
      setFieldValue("release_date", res.data.release_date);
      setFieldValue("gpl_directory", res.data.gpl_directory);
      setFieldValue("gpl_lisence", res.data.gpl_lisence);
      setFieldValue("version_desc", res.data.version_desc);
      setDev_id(res.data.dev_id);
    });
  }, [ver_id]);

  const initialValues = {
    version: "",
    version_desc: "",
    release_date: "",
    gpl_directory: "",
    gpl_lisence: "",
  };

  const validationSchema = Yup.object({
    version: Yup.string().required("Required"),
    version_desc: Yup.string().required("Required"),
    release_date: Yup.date().required("Required"),
    gpl_directory: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z])(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
        "Enter Valid url example: 'https://github.com/git_repoistory'"
      )
      .required("Required"),
    gpl_lisence: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z])(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
        "Enter Valid file url example: 'https://github.com/git_repoistory/file'"
      )
      .required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    updateVersion(ver_id, values)
      .then((res) => {
        if (res.data.success === 1) {
          setMessage("Version Updated!  Redirecting to verions...");
          setToastVisible(true);
          resetForm({ values: "" });
          setTimeout(() => {
            cancel();
          }, 2000);
        } else {
          setMessage(res.data.message);
          setToastVisible(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setToastVisible(true);
      });
  };

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <PageTitle title={props.title} />
      <Toast
        text={
          <>
            <strong>{message}</strong>
          </>
        }
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <SideBar />
      <div className="dashboard">
        <div className="mt-50 contact-form">
          <div className="dashboard-container">
            <Text tagName="h1" headingSize="m">
              Update version
            </Text>
            {version && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {(formik) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="col-2-layout">
                        <div className="input">
                          <FormField
                            valid={touched.version && !errors.version}
                            shouldValidate={touched.version}
                            validation={{
                              msg: errors.version,
                              id: "Error-id:",
                            }}
                          >
                            <InputField
                              value={values.version}
                              onChange={handleChange}
                              // onChange={setFieldValue}
                              onBlur={handleBlur}
                              id="version"
                              name="version"
                              label="Version"
                            />
                          </FormField>
                        </div>

                        <div className="input">
                          <label className="label">Release Date</label>
                          <input
                            type="date"
                            className="date-picker"
                            value={values.release_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min="2000-01-01"
                            max="2100-01-01"
                            id="release_date"
                            name="release_date"
                            label="release_date"
                          />
                          <InputError
                            touched={touched.release_date}
                            errors={errors.release_date}
                          />
                        </div>
                      </div>
                      <div className="col-2-layout">
                        <div className="input">
                          <FormField
                            valid={
                              touched.gpl_directory && !errors.gpl_directory
                            }
                            shouldValidate={touched.gpl_directory}
                            validation={{
                              msg: errors.gpl_directory,
                              id: "Error-id:",
                            }}
                          >
                            <InputField
                              value={values.gpl_directory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="gpl_directory"
                              name="gpl_directory"
                              label="Location / URL of GPL code"
                            />
                          </FormField>
                        </div>
                        <div className="input">
                          <FormField
                            valid={touched.gpl_lisence && !errors.gpl_lisence}
                            shouldValidate={touched.gpl_lisence}
                            validation={{
                              msg: errors.gpl_lisence,
                              id: "Error-id:",
                            }}
                          >
                            <InputField
                              value={values.gpl_lisence}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="gpl_lisence"
                              name="gpl_lisence"
                              label="Location / URL of License Information"
                            />
                          </FormField>
                        </div>
                      </div>
                      <div className="input">
                        <FormField
                          valid={touched.version_desc && !errors.version_desc}
                          shouldValidate={touched.version_desc}
                          validation={{
                            msg: errors.version_desc,
                            id: "Error-id:",
                          }}
                        >
                          <TextArea
                            value={values.version_desc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="version_desc"
                            name="version_desc"
                            label="Version Description"
                            style={{ height: "200px" }}
                          />
                        </FormField>
                      </div>

                      <Button
                        type="secondary"
                        onClick={cancel}
                        text="Go Back"
                        small
                      />
                      <Button
                        type="primary"
                        htmlType="submit"
                        text="Update"
                        small
                      />
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
