import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDeviceDetails } from "../Services/devices";
import fileDownload from "js-file-download";
import PageTitle from "./PageTitle";

// Dependencies
import "@ingka/svg-icon/style.scss";
import "@ingka/image-info/style.scss";
import "@ingka/aspect-ratio-image/style.scss";
import "@ingka/text/style.scss";
import "@ingka/button/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/accordion/style.scss";
import "@ingka/toast/style.scss";

// Components
import AspectRatioImage from "@ingka/aspect-ratio-image";
import Text from "@ingka/text";
import Toast from "@ingka/toast";
import Button from "@ingka/button";
import Accordion, { AccordionItem } from "@ingka/accordion";
import Footer from "./Common/Footer";
import Navbar from "./Common/Navbar";
import chevronLeft from "@ingka/ssr-icon/paths/chevron-left";
import SSRIcon from "@ingka/ssr-icon";

export default function Product(props) {
  const navigate = useNavigate();
  const [device, setdevice] = React.useState([]);
  const [versions, setVersions] = React.useState([]);
  const [faqs, setFaqs] = React.useState([]);
  const [toastVisible, setToastVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { id } = useParams();

  useEffect(() => {
    getDeviceDetails(id).then((res) => {
      setdevice(res.data);
      setVersions(res.data.versions);
      setFaqs(res.data.faqs);
    });
  }, [id]);

  /** This function will fetch the raw content of file and download the file on user end.
   * @func getLisence
   * @param {string} link github link to the file
   */
  function getLisence(link) {
    link = link.replace("blob/", "").replace("github", "raw.githubusercontent");
    fetch(link)
      .then(async (response) => {
        let content = await response.text();
        if (response.status === 404 || response.status === 400) {
          setMessage("File not found");
          setToastVisible(true);
        } else {
          fileDownload(content, `${device.device_name} lisence.txt`);
        }
      })
      .catch((error) => {
        setMessage("Error Occured or File not found");
        setToastVisible(true);
      });
  }
  /** This function will open the download zip link of github directory in new window.
   * @function getZip
   * @param {string} link github project root directory
   */
  function getZip(link) {
    window.open(link);
  }

  return (
    <>
      <PageTitle title={`Ikea GPL | ${device.device_name}`} />
      <Toast
        text={
          <>
            <strong>{message}</strong>
          </>
        }
        isOpen={toastVisible}
        onCloseRequest={() => {
          setToastVisible(false);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
      <Navbar />
      <hr />
      <div className="container">
        <button
          className="go-back"
          onClick={() => {
            navigate(-1);
            console.log("ioiooi");
          }}
        >
          <SSRIcon paths={chevronLeft} />
        </button>
      </div>
      <header className="mt-50">
        <div className="container">
          <div className="col-2-layout">
            <AspectRatioImage
              alt="Img alt text"
              ratio="wide"
              src={device.img}
            />
            <div className="container">
              <Text tagName="h1" headingSize="xl">
                {device.device_name}
              </Text>
              <p className="mt-50">{device.device_desc}</p>
            </div>
          </div>
        </div>
      </header>
      <div className="release mt-150">
        <div className="container">
          <Text tagName="h1" headingSize="xl" align="center">
            Releases
          </Text>
          <div className="mt-100">
            <Accordion>
              {versions.map((item, index) => (
                <AccordionItem
                  key={item.version_id}
                  id={item.version_id}
                  onHeadingClicked={function noRefCheck() {}}
                  title={"Version: " + item.version}
                >
                  <p>{item.version_desc}</p>
                  <p className="mt-50">
                    <strong>Release Date:</strong>
                    <span className="light-color ml-30 ">
                      {item.release_date}
                    </span>
                  </p>
                  <div>
                    <Button
                      className="mt-50"
                      type="primary"
                      text="Download GPL Code"
                      onClick={() => {
                        getZip(item.gpl_directory);
                      }}
                    />
                    <Button
                      className="mt-50"
                      type="secondary"
                      text="Download License Information"
                      onClick={() => {
                        getLisence(item.gpl_lisence);
                      }}
                    />
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <div className="faq mt-150">
        <div className="container">
          <Text tagName="h1" headingSize="xl" align="center">
            Frequently Asked Questions
          </Text>
          <div className="mt-100">
            <Accordion>
              {faqs.map((item, index) => (
                <AccordionItem key={index} id={index} title={item.question}>
                  <p>{item.answer}</p>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
