import React from "react";
import { sec } from "../Auth/security";
import { useNavigate } from "react-router-dom";

export default function CallBack(props) {
  const navigate = useNavigate();

  if (sec.isAuthenticated()) {
    navigate("/admin/devices");
  } else {
    navigate("/login");
  }
  return <></>;
}
