import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Login2(props) {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect();

  return <></>;
}
