import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingScreen from "../Components/LoadingScreen";

export default function ProtectedRoute({ component, ...props }) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingScreen />,
  });
  return <Component title={props.title} />;
}
