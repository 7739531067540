// Dependencies
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/aspect-ratio-image/style.scss";
import "@ingka/image-info/style.scss";
import "@ingka/card/style.scss";
import "@ingka/focus/style.scss";

// Components
import Button from "@ingka/button";
import AspectRatioImage from "@ingka/aspect-ratio-image";
import Text from "@ingka/text";

export default function DashboardDeviceCard(props) {
  return (
    <>
      <div className="mb-50">
        <a target={"blank"} href={props.href}>
          <AspectRatioImage
            alt="Img alt text"
            ratio="wide"
            src={props.imgURL}
          />
        </a>
        <Text tagName="p" bodySize="m">
          {props.versions + " versions"}
        </Text>
        <Text tagName="h4" headingSize="m" className="m-0">
          {props.device_name}
        </Text>
        <Text tagName="p" bodySize="m">
          {props.device_desc}
        </Text>

        <Button
          className="mr-10"
          type="primary"
          text="Edit Device"
          small
          href={"/admin/device-edit/" + props.device_id}
        />
        <Button
          id={props.device_id}
          className="mr-10"
          type="secondary"
          text="Delete Device"
          iconOnly
          small
          onClick={() => {
            props.modal(true);
            props.handleRemove(props.device_id);
          }}
        />
        <Button
          className="mr-10"
          type="emphasised"
          href={"/admin/version-view/" + props.device_id}
          text="Versions"
          small
        />
        <Button
          className="mr-10"
          type="secondary"
          href={"/admin/faq/" + props.device_id}
          text="FAQ"
          small
        />
      </div>
    </>
  );
}
