import React, { useEffect } from "react";
import { getVersions, deleteVersion as del } from "../../Services/versions";
import Modal from "react-modal/lib/components/Modal";
import { useParams } from "react-router-dom";

// Dependencies
import "@ingka/page-container/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/search/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/button/style.scss";
import "@ingka/grid/style.scss";

// Components
import Text from "@ingka/text";
import Button from "@ingka/button";
import VersionCard from "./DashboardCompoenents/VersionCard";
import SideBar from "./SideBar";
import PageTitle from "../PageTitle";

export default function VersionView(props) {
  const [versions, setVersions] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [removeVersion, setRemoveVersion] = React.useState();

  const { id } = useParams();

  useEffect(() => {
    getVersions(id).then((res) => {
      setVersions(res.data);
    });
  }, [id]);

  function handleRemove(id) {
    setRemoveVersion(id);
  }

  function deleteVersion() {
    del(removeVersion).then((res) => {
      setModal(false);
      window.location.reload();
    });
  }

  return (
    <>
      <PageTitle title={props.title} />
      <Modal
        className="Modal"
        isOpen={modal}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="modal-body">
          <div>
            <Text tagName="h1" headingSize="m" align="center">
              All information related to this Version will be deleted.
              <br />
              <br />
              Confirm delete Version?
            </Text>
            <div>
              <Button
                type="secondary"
                onClick={() => {
                  setModal(false);
                }}
                text="Cancel"
              />
              <Button
                type="primary"
                onClick={() => {
                  deleteVersion();
                }}
                text="Delete"
              />
            </div>
          </div>
        </div>
      </Modal>
      <SideBar />
      <div className="dashboard">
        <div className="mt-50">
          <div className="dashboard-container">
            <div className="d-flex-between">
              <Text tagName="h1" headingSize="m">
                Versions
              </Text>
              <Button
                className="mr-10"
                href={"/admin/version-add/" + id}
                type="primary"
                text="Add Version"
                small
              />
            </div>

            <div id="DevicesSection" className=" mt-50 col-3-layout">
              {versions.map((item, index) => (
                <VersionCard
                  key={index}
                  version_id={item.version_id}
                  version={item.version}
                  version_desc={item.version_desc.substr(0, 150) + "..."}
                  release_date={item.release_date}
                  gpl_directory={item.gpl_directory}
                  gpl_lisence={item.gpl_lisence}
                  modal={setModal}
                  handleRemove={handleRemove}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
